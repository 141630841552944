import styled from 'styled-components';
import { LAYOUT, media } from '@app/styles/themes/constants';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

export const RightSideCol = styled(BaseCol)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${LAYOUT.desktop.headerHeight});
  background-color: var(--sider-background-color);
  overflow-y: auto;
`;

export const LeftSideCol = styled(BaseCol)`
  @media only screen and ${media.xl} {
    padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
    height: calc(100vh - ${LAYOUT.desktop.headerHeight});
    overflow: auto;
  }
`;

export const Space = styled.div`
  margin: 1rem 0;
`;

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;

  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`;

export const StockNotesScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 450px;

  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    ul {
      li {
        list-style: none;
      }
    }
  }
`;

export const AnalysisScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 450px;

  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    ul {
      margin: 0 0 1em 2em;
      li {
        ul {
          margin: 0 0 1em 2em;
          li {
          }
        }
      }
    }
    hr {
      margin-bottom: 1em;
    }
    .x2tk-upgrade {
      padding: 2em;
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
`;

export const SymbolChartWrapper = styled.div`
  .ant-card-body {
    padding-bottom: 0;
  }
  .ant-card-head {
    .ant-card-head-title {
      padding-bottom: 0.9em;
    }
  }
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 15px;

  background: black;

  min-height: 300px;
  overflow-y: auto;
`;

export const Item = styled.div`
  background: red;
  height: 150px;
  flex-shrink: 0;
`;
