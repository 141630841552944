import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NFTCard } from '@app/components/nft-dashboard/common/NFTCard/NFTCard';
import * as S from './WatchList.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { httpApi } from '@app/api/http.api';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { notificationController } from '@app/controllers/notificationController';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';

export const WatchList: React.FC = () => {
  const { t } = useTranslation();
  const [symbols, setSymbols] = useState([]);
  const [newSymbol, setNewSymbol] = useState('');
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const navigate = useNavigate();
  //const [isLoading, setLoading] = useState(false);
  // const [connection, setConnection] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await httpApi.get('api/watchlists/symbols', {}).then(({ data }) => data);
        // eslint-disable-next-line
        const symbolsData = response.data.map((result: any) => ({ ...result, key: result.symbol }));
        //console.log(symbolsData);
        setSymbols(symbolsData);
      } catch (error) {
        // message.error('Failed to fetch symbols');
        // return [];
      }
    };
    fetchData();
  }, []);

  const columns = [
    { title: t('x2tk.symbol'), dataIndex: 'symbol', key: 'symbol' },
    { title: t('x2tk.name'), dataIndex: 'short_name_vi', key: 'name' },
    { title: t('x2tk.price'), dataIndex: 'price', key: 'price' },
    {
      title: t('x2tk.price'),
      key: 'action',
      render: (text: string, record: { symbol: string }) => {
        return (
          <BaseSpace>
            <BaseButton type="default" danger onClick={() => handleRemoveSymbol(record.symbol)}>
              {t('tables.delete')}
            </BaseButton>
          </BaseSpace>
        );
      },
    },
  ];

  // Add a new symbol to the watchlist
  const handleAddSymbol = async () => {
    if (!newSymbol.trim()) {
      notificationController.error({ message: t('x2tk.forms.watchlist.symbolErrorMsg') });
      return;
    }

    try {
      const response = await httpApi.post('api/watchlists/add_symbol', { symbol: newSymbol }).then(({ data }) => data);
      // eslint-disable-next-line
      const symbolsData = response.data;
      symbolsData['key'] = symbolsData['symbol'];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSymbols([...symbols, symbolsData]); // Add the new symbol to the state
      //console.log(symbolsData);
      //console.log(symbols);
      form.resetFields();
      // setNewSymbol(''); // Clear the input field
      notificationController.success({ message: t('x2tk.forms.watchlist.symbolAddedSuccess') });
      // eslint-disable-next-line
    } catch (error: any) {
      //console.error('Error adding symbol:', error);
      notificationController.error({ message: t('x2tk.forms.watchlist.symbolAddedError') + ': ' + error.message });
    }
  };

  const handleRemoveSymbol = async (symbol: string) => {
    try {
      await httpApi.post('api/watchlists/delete_symbol/' + symbol, {}).then(({ data }) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setSymbols((prevSymbols) => prevSymbols.filter((s) => s.symbol !== symbol));
        notificationController.success({ message: t('x2tk.forms.watchlist.symbolDeletedSuccess') });
        return data;
      });
    } catch (error) {
      //console.error('Error adding symbol:', error);
      notificationController.error({ message: t('x2tk.forms.watchlist.symbolDeletedError') });
    }
  };

  return (
    <BaseRow>
      <BaseCol span={24}>
        <S.TitleText level={2}>{t('x2tk.watchlist')}</S.TitleText>
      </BaseCol>

      <BaseCol span={24}>
        <NFTCard isSider>
          <BaseRow gutter={[30, 30]}>
            <BaseCol span={24}>
              <BaseRow gutter={[14, 14]}>
                <S.WatchListCover>
                  <BaseButtonsForm.Provider>
                    <BaseButtonsForm
                      name="addSymbolForm"
                      form={form}
                      isFieldsChanged={isFieldsChanged}
                      onFinish={handleAddSymbol}
                      footer={<div></div>}
                      onFieldsChange={() => setFieldsChanged(true)}
                    >
                      <BaseButtonsForm.Item
                        name="newSymbol"
                        rules={[{ required: true, message: t('x2tk.forms.watchlist.symbolError') }]}
                      >
                        <BaseInput
                          placeholder={t('x2tk.forms.watchlist.enterSymbol')}
                          value={newSymbol}
                          onChange={(e) => setNewSymbol(e.target.value)}
                        />
                      </BaseButtonsForm.Item>
                      <BaseButtonsForm.Item>
                        {/* type="default" htmlType="button" onClick={handleAddSymbol} */}
                        <S.AddSymbolButton type="primary" htmlType="submit">
                          {t('x2tk.add')}
                        </S.AddSymbolButton>
                      </BaseButtonsForm.Item>
                    </BaseButtonsForm>
                  </BaseButtonsForm.Provider>
                </S.WatchListCover>
                <S.Table
                  size={'small'}
                  pagination={false}
                  columns={columns}
                  dataSource={symbols}
                  onRow={(record) => ({
                    onClick: () => {
                      navigate('/technical/' + record.symbol); // Navigate to the path on row click
                    },
                  })}
                />
              </BaseRow>
            </BaseCol>
          </BaseRow>
        </NFTCard>
      </BaseCol>
    </BaseRow>
  );
};
