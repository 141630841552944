import React from 'react';
import * as S from './MarketPulseCard.styles';
import { NFTCardHeader } from '@app/components/nft-dashboard/common/NFTCardHeader/NFTCardHeader';
import { useTranslation } from 'react-i18next';

export const MarketPulseCard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <NFTCardHeader title={t('x2tk.marketPulse')}></NFTCardHeader>
      <S.SectionWrapper>
        <S.MarketPulseCard padding={0}>
          <S.Description>
            31/03/2025: VNIndex điều chỉnh về vùng hỗ trợ quan trọng 1.300 – 1.310. Cầu chủ động vẫn khá thận trọng và
            chỉ giúp thu hẹp biên độ giảm ở mức khiêm tốn. Tín hiệu động lượng Stochastic giữ trong vùng quá bán, bước
            đầu ủng hộ kỳ vọng đà giảm của VNIndex sẽ tạm chững lại quanh khu vực trên. Đồng thời, tiếp tục quan sát khả
            năng cân bằng để xác định hướng đi tiếp theo của chỉ số.
          </S.Description>
        </S.MarketPulseCard>
      </S.SectionWrapper>
    </>
  );
};
