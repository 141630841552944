export interface RecoStocks {
  symbol: string;
  industry: string;
  entryPrice: number;
  exitPrice: number;
  weight: number;
  closePrice: number;
  targetPrice: number;
  cutlossPrice: number;
}

export const results: RecoStocks[] = [
  {
    symbol: 'HVN',
    industry: 'Hàng không',
    entryPrice: 28.8,
    exitPrice: 19.8,
    weight: 10,
    closePrice: 28.8,
    targetPrice: 31.4,
    cutlossPrice: 28.2,
  },
  {
    symbol: 'IDI',
    industry: 'Thủy sản',
    entryPrice: 7.7,
    exitPrice: 7.63,
    weight: 10,
    closePrice: 7.63,
    targetPrice: 8.15,
    cutlossPrice: 7.55,
  },
].map((result, index) => ({ ...result, key: index }));
